var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shifts__wrapper" },
    [
      _c("div", { staticClass: "page-inner" }, [
        _c("div", { staticClass: "page-title-wrapper" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "page-title-inner" }, [
              _c("div", { staticClass: "shifts__title page-title" }, [
                _vm._v("Shifts")
              ]),
              _c(
                "button",
                {
                  staticClass: "button button--fill",
                  on: { click: _vm.showAddShift }
                },
                [
                  _c("i", { staticClass: "ri-add-circle-fill" }),
                  _c("span", [_vm._v("Add New Shift")])
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "icon-button icon-button--round icon-button--mobile icon-button--blue button-fly",
                  on: { click: _vm.showAddShift }
                },
                [_c("i", { staticClass: "ri-add-circle-fill" })]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "table" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "table__main" },
            _vm._l(_vm.shifts, function(shift) {
              return _c("Shift", { key: shift.id, attrs: { shift: shift } })
            }),
            1
          )
        ])
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table__title-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "table__title-inner" }, [
          _c("div", { staticClass: "table__title-item" }, [_vm._v("On/Off")]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Shift Name")
          ]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Daily Goal")
          ]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Weekly Goal")
          ]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Monthly Goal")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }