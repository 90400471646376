<template>
  <div class="shifts__wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="shifts__title page-title">Shifts</div>
            <button class="button button--fill" @click="showAddShift">
              <i class="ri-add-circle-fill" />
              <span>Add New Shift</span>
            </button>
            <button
              class="icon-button icon-button--round icon-button--mobile icon-button--blue button-fly"
              @click="showAddShift"
            >
              <i class="ri-add-circle-fill"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="table">
        <div class="table__title-wrapper">
          <div class="container">
            <div class="table__title-inner">
              <div class="table__title-item">On/Off</div>
              <div class="table__title-item">Shift Name</div>
              <div class="table__title-item">Daily Goal</div>
              <div class="table__title-item">Weekly Goal</div>
              <div class="table__title-item">Monthly Goal</div>
            </div>
          </div>
        </div>
        <div class="table__main">
          <Shift v-for="shift in shifts" :key="shift.id" :shift="shift" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Shift from '@/views/Admin/Shifts/Shift'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Shifts',
  components: {Shift, InfiniteLoading},
  data: () => ({
    isLoading: false
  }),
  computed: {
    shifts() {
      return this.$store.getters['shifts/shifts']
    },
    hasMoreResults() {
      return this.shifts.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['shifts/pagination'].total
    },
    page() {
      return this.$store.getters['shifts/page']
    }
  },
  async created() {
    if (!this.$store.getters['shifts/pagination'].total) {
      this.$store.dispatch('shifts/fetch')
    }
  },
  methods: {
    showAddShift() {
      this.$root.$emit('ShowSidebar', 'AddShift')
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('shifts/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
