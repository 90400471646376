<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner" :class="{'table__row--dec': !shift.isActive}">
        <div class="table__cell">
          <el-popover v-model="visible" placement="bottom-start">
            <span v-if="!!shift.isActive">Deactivate </span>
            <span v-else>Activate </span>
            <span>{{ shift.name }}?</span>
            <div style="display: flex; justify-content: space-between; margin-top: 12px">
              <el-button size="mini" type="plain" @click="visible = false">cancel</el-button>
              <el-button type="primary" size="mini" @click="changeStatus">confirm</el-button>
            </div>
            <el-switch slot="reference" :value="shift.isActive" />
          </el-popover>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">
            <span class="table__cell--round" :style="{backgroundColor: shift.color}"></span>
            {{ shift.name }}
          </div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">$ {{ shift.dailyGoal }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">$ {{ shift.weeklyGoal }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">$ {{ shift.monthlyGoal }}</div>
        </div>
        <div class="table__cell table__cell--end">
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item class="item--edit" command="Edit">
                <i class="ri-pencil-fill"></i> Edit
              </el-dropdown-item>
              <el-dropdown-item class="item--remove" command="Remove">
                <i class="ri-delete-bin-7-fill"></i> Remove
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <span class="table__cell--round" :style="{backgroundColor: shift.color}"></span>
          </div>
          <div class="card__box">
            <div class="card__title">{{ shift.name }}</div>
          </div>
          <div class="card__box" @click.stop>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit" command="Edit">
                  <i class="ri-pencil-fill"></i> Edit
                </el-dropdown-item>
                <el-dropdown-item class="item--remove" command="Remove">
                  <i class="ri-delete-bin-7-fill"></i> Remove
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="card__body">
          <div class="card__line">
            <div class="card__subtitle">Daily goal</div>
            <div class="card__title">$ {{ shift.dailyGoal }}</div>
          </div>
          <div class="card__line">
            <div class="card__subtitle">Weekly goal</div>
            <div class="card__title">$ {{ shift.weeklyGoal }}</div>
          </div>
          <div class="card__line">
            <div class="card__subtitle">Month goal</div>
            <div class="card__title">$ {{ shift.monthlyGoal }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import Dropdown from '@/components/Dropdown'
import Modal from '@/components/ToggleModal'

export default {
  name: 'Shift',
  components: {
    ToggleButton,
    Dropdown
  },
  props: {
    shift: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    value: 'dropdown',
    options: ['Edit', 'Remove'],
    visible: false
  }),
  methods: {
    action(act) {
      if (act === 'Edit') {
        this.$root.$emit('ShowSidebar', {
          sidebarName: 'AddShift',
          componentData: this.shift
        })
      } else if (act === 'Remove') {
        try {
          this.$store.dispatch('shifts/delete', this.shift.id)
          this.$store.commit('shifts/change', this.shift.id)
        } catch (e) {}
      }
    },
    async changeStatus() {
      try {
        if (this.shift.isActive) {
          await this.$store.dispatch(`shifts/deactivate`, this.shift.id)
        } else {
          await this.$store.dispatch(`shifts/activate`, this.shift.id)
        }
        this.shift.isActive = !this.shift.isActive
        this.$store.commit(`shifts/change`, this.shift)
        this.visible = false
      } catch (e) {
        alert(e.response.data.error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
